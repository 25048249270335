import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { MY_SERVER } from '../../app/globe'
import "./Navigation.css"
import { useTranslation } from "react-i18next";




export const Navigation = () => {
    const { t, i18n } = useTranslation('common');

    

    return (
        <div className="navigation" >
            

                
                




                <ul className="menu">
                    <li>
                        <a className="icon alerts active">
                        {t('navigation.alerts', { framework: 'React' })} 
                            
                        </a>
                    </li>



                </ul>
            

        </div>
    );
}