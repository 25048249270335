import axios from 'axios';
import {MY_SERVER} from '../../app/globe'
import { useSelector, useDispatch } from 'react-redux';
import {getErrors} from './alertsSlice'



export function getAlertDetails(alert_token) {
      return new Promise((resolve) =>
        axios.get(MY_SERVER+`/alert/${alert_token}`)
        .then((res) => resolve({ data: res.data }))
        .catch(error => {
          if (error.response.status === 404) {
           
            resolve({ data: error.response.status })
            
          }
        })
      );
    }


    export function changeAlertStatus( data) {
      
      return new Promise((resolve) =>
        axios.post(MY_SERVER+`/alert/${data.alert_token}`, data).then((res) => resolve({ data: res.data }))
      );
    }

    export function declareAlertResolved( data) {
      return new Promise((resolve) =>
        axios.post(MY_SERVER+`/declare_alert_resolved/${data.alert_token}`, data).then((res) => resolve({ data: res.data }))
      );
    }

    export function send_message( data) {
      return new Promise((resolve) =>
        axios.post(MY_SERVER+`/send_message/${data.alert_token}`).then((res) => resolve({ data: res.data }))
      );
    }




   