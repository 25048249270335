import React from 'react';
import { Outlet, Link } from "react-router-dom";
import './App.css'
import { Header } from './features/header/Header';
import { Navigation } from './features/navigation/Navigation';

function App() {
  return (
    <div >
      <header  className='header'>
        <Header></Header>
      </header>


      <div className="main" >
        {window.innerWidth >= 900 ?
         <div>
          <Navigation></Navigation>
         </div> : 
         ''}
        

        <div className='content'>
          <Outlet />
        </div>
        
      </div>
    </div>
  );
}

export default App;
