import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getAlertDetails, changeAlertStatus, declareAlertResolved } from './alertsAPI'


const initialState = {

  alertId: 0,
  alertToken: 0,
  alertDetails: [],
  create_at: "",
  edge_device_id: "",
  camera_id: "",
  alert_status: "",
  url_video: "",
  url_snapshot: "",
  is_resolved: false,
  camera_longitude: 0.0,
  camera_latitude: 0.0,
  timeSinceAlert: "",
  alertActions: [],
  title: "",
  alertExpiration: false,
  cameraLocation: "",
  cameraAddress: "",
  isExpire: false, 
  secondsToExpire: 0.0,
  test: 0,
  errors: 0


};


export const getDetailsAlertAsync = createAsyncThunk(
  'alerts/getAlertDetails',
  async (alert_token) => {
    const response = await getAlertDetails(alert_token);
    return response.data;
  }
);

export const changeAlertStatusAsync = createAsyncThunk(
  'alerts/changeAlertStatus',
  async (data) => {

    const response = await changeAlertStatus(data);
    return response.data;
  }
);

export const declareAlertResolvedAsync = createAsyncThunk(
  'alerts/declareAlertResolved',
  async (data) => {

    const response = await declareAlertResolved(data);
    return response.data;
  }
);





export const alertsSlice = createSlice({
  name: 'alerts',
  initialState,
  reducers: {
    getErrors:  (state, action) => {
      state.errors = true
    },


    getTimeSinceAlert: (state, action) => {
      var alertCreatedOn = new Date(state.create_at)
      var now = new Date()
      var delta = (now.getTime() - alertCreatedOn.getTime()) / 1000;
      function toDaysMinutesSeconds(delta) {
        const seconds = Math.floor(delta % 60);
        const minutes = Math.floor((delta % 3600) / 60);
        const hours = Math.floor((delta % (3600 * 24)) / 3600);
        const days = Math.floor(delta / (3600 * 24));
        const secondsStr = makeHumanReadable(seconds, 'second');
        const minutesStr = makeHumanReadable(minutes, 'minute');
        const hoursStr = makeHumanReadable(hours, 'hour');
        const daysStr = makeHumanReadable(days, 'day');
        let result = `${daysStr}${hoursStr}${minutesStr}${secondsStr}`.replace(/,\s*$/, '')
        return result;
      }

      function makeHumanReadable(num, singular) {
        return num > 0
          ? num + (num === 1 ? ` ${singular}, ` : ` ${singular}s, `)
          : '';
      }

      state.timeSinceAlert = toDaysMinutesSeconds(delta)
      

    },
    addAction: (state,action) => {
        const date = new Date()
        const day = date.getDate()
        const dayWithZero = (day < 10 ? '0' : '') + day;
        const month = date.getMonth() + 1
        const monthWithZero = (month < 10 ? '0' : '') + month;
        const year = date.getFullYear()
        const hour = date.getHours()
        const hourWithZero = (hour < 10 ? '0' : '') + hour;
        const minutes = date.getMinutes()
        const minutesWithZero = (minutes < 10 ? '0' : '') + minutes;
        const seconds = date.getSeconds()
        const secondsWithZero = (seconds < 10 ? '0' : '') + seconds;
        const full_time = `${dayWithZero}/${monthWithZero}/${year}, ${hourWithZero}:${minutesWithZero}:${secondsWithZero}`

        const   data = {
            "alert_id": action.payload.alert_id,
            "actions": action.payload.action,
            "action_date": full_time
        }
        state.alertActions.unshift(data)
        

    },
    


  },
  extraReducers: (builder) => {
    builder
      
      
      .addCase(getDetailsAlertAsync.fulfilled, (state, action) => {
        state.errors = action.payload
        state.alertId = action.payload.id
        state.alertToken = action.payload.token
        state.create_at = action.payload.create_at
        state.edge_device_id = action.payload.edge_device_id
        state.camera_id = action.payload.camera_id
        state.alert_status = action.payload.alert_status
        state.url_video = action.payload.url_video
        state.url_snapshot = action.payload.url_snapshot
        
        state.camera_latitude = action.payload.camera_latitude
        state.camera_longitude = action.payload.camera_longitude
        state.alertActions = action.payload.actions
        state.title = action.payload.title
        state.cameraLocation= action.payload.camera_location
        state.cameraAddress = action.payload.camera_address
        state.isExpire= action.payload.is_expire
        state.secondsToExpire = action.payload.time_until_expire
        if (state.alert_status == 'is_emergency_resolved' ) {state.is_resolved = true }
        if (state.alert_status == 'is_not_emergency_resolved' ) {state.is_resolved = true }
        

        
        
      })
     

      .addCase(changeAlertStatusAsync.fulfilled, (state, action) => {

        state.alert_status = action.payload.alert_status
        
        
      })


      .addCase(declareAlertResolvedAsync.fulfilled, (state, action) => {

        state.alert_status = action.payload.alert_status
        state.is_resolved = true
        
      })



  },
});



export const {getErrors, getTimeSinceAlert, addAction} = alertsSlice.actions;
export const selectErrors = (state) => state.alerts.errors
export const selectTimeUntilExpire = (state) => state.alerts.secondsToExpire
export const selectIsExpire = (state) => state.alerts.isExpire
export const selectCameraAddress = (state) => state.alerts.cameraAddress
export const selectCameraLocation = (state) => state.alerts.cameraLocation
export const selectTimeSinceAlert = (state) => state.alerts.timeSinceAlert
export const selectAlertTitle = (state) => state.alerts.title
export const selectAlertActions = (state) => state.alerts.alertActions
export const selectAlertId = (state) => state.alerts.alertId
export const selectCameraLongitude = (state) => state.alerts.camera_longitude
export const selectCameraLatitude = (state) => state.alerts.camera_latitude
export const selectIsResolved = (state) => state.alerts.is_resolved
export const selectCreateAt = (state) => state.alerts.create_at
export const selectEdgeDeviceId = (state) => state.alerts.edge_device_id
export const selectCameraId = (state) => state.alerts.camera_id
export const selectAlertStatus = (state) => state.alerts.alert_status
export const selectVideoPath = (state) => state.alerts.url_video
export const selectSnapshotPath = (state) => state.alerts.url_snapshot
export const selectAlertDetails = (state) => state.alerts.alertDetails
export default alertsSlice.reducer;