import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import {alertMessage, selectErrors, selectIsExpire,selectTimeUntilExpire,  selectCameraAddress, selectCameraLocation,    selectTimeSinceAlert, selectAlertTitle, selectAlertActions, selectAlertId, addAction, getTimeSinceAlert, selectCameraLatitude, selectCameraLongitude, getDetailsAlertAsync, changeAlertStatusAsync, declareAlertResolvedAsync, selectAlertStatus, selectCameraId, selectEdgeDeviceId, selectSnapshotPath, selectCreateAt, selectVideoPath, selectIsResolved } from "./alertsSlice"
import { MY_SERVER } from '../../app/globe'
import "./AlertDetails.css"
import {Map} from '../Map/Map'
import { Viewer } from '../3d_viewer/Viewer';
import share_image from './static/share.png'
import cloche_image from './static/cloche.png'
import undo_arrow from './static/undo_arrow.png'
import check_mark from './static/check_mark.png'
import { useTranslation } from "react-i18next";

// to test issues 


// need url like this https://my-website.com?id=cdf2de0664794807bf3444ba81a8c2fd
export const AlertFiles = () => {

    const { t, i18n } = useTranslation('common');

    const queryParameters = new URLSearchParams(window.location.search)
    const token = queryParameters.get("id")
    const dispatch = useDispatch();
    const timeUntilExpire = useSelector(selectTimeUntilExpire)
    const isExpire = useSelector(selectIsExpire)
    const title = new Date(useSelector(selectAlertTitle)).toLocaleString()
    const timeSinceAlert = useSelector(selectTimeSinceAlert)
    const alertId = useSelector(selectAlertId);
    const alertCreateAt =  new Date(useSelector(selectCreateAt)).toLocaleString();
    const alertstatus = useSelector(selectAlertStatus);
    const cameraId = useSelector(selectCameraId);
    const snapshot = useSelector(selectSnapshotPath);
    const video = useSelector(selectVideoPath);
    const isResolvedBack = useSelector(selectIsResolved)
    const [isResolvedUser, setIsResolvedUser] = useState(false)
    const device_id = useSelector(selectEdgeDeviceId)
    const camera_latitude = useSelector(selectCameraLatitude)
    const camera_longitude = useSelector(selectCameraLongitude)
    const alertActions = useSelector(selectAlertActions)
    const [answer, setanswer] = useState(false)
    const cameraAddress = useSelector(selectCameraAddress)
    const cameraLocation = useSelector(selectCameraLocation)
    const errors = useSelector(selectErrors)
    const [firstMessageSend, setFirstMessageSend] = useState(false)
    
    const [showFullSnapshot, setShowFullSnapshot] = useState(false);
    

    const handleClickSnapshot = () => {
        setShowFullSnapshot(!showFullSnapshot);
        
    }

    useEffect(() => {
        dispatch(getDetailsAlertAsync(token))
        
    }, [])

    useEffect(() => {
        setInterval(() => {
            dispatch(getTimeSinceAlert())
        }, 1000);
    }, [])

    

    // useEffect(() => {
    //     setTimeout(() => {
    //         console.log(timeUntilExpire)
    //     }, 8000);
    // }, [])

  
    
    
    // useEffect(() => {
    //     setTimeout(() => {
    //         dispatch(getDetailsAlertAsync(token))
    //     }, timeUntilExpire);
    // }, [timeUntilExpire])


    // setTimeout(() => {
    //             dispatch(getDetailsAlertAsync(token))
    //         }, timeUntilExpire)
    


    
   
// console.log(window.innerWidth)
    



    return (
        <div >
            
            
            {errors != 404 ? <div> { !isExpire ? <div> 
            <h1 className="title">{t('alerts.alert', { framework: 'React' })} &rsaquo; {title} </h1>
         
            <div className= "div-alertDetails gap-40">
                <div  className= "w-700">
                    <div className="content-box box-shadow space-between">
                        <div className="flex gap-20 justify-between">
                            <h5 className="title">{cameraAddress}</h5>
                        </div>
                        <div className="video">
                            <video id="alert_video" src={video} controls autoPlay muted loop ></video>
                        </div>
                    </div>
                    
                    
                </div>
                <div  className='div-column' >
                    
                    
                    <div className="box-shadow">
                        <img id="myImg_ezechiel" src={snapshot} className="rounded flex snapshot_tall" alt="Demo" onClick={handleClickSnapshot} />
                        {showFullSnapshot && <div className={`fullscreen ${showFullSnapshot ? 'active' : ''}`}>
                            <img src={snapshot} alt="Demo" onClick={handleClickSnapshot} />
                        </div>}
                    </div>
                   
                </div>
                <div  className='div-column'>
                   
                    <div className="content-box-rounded box-shadow tall-time-since-alert ">
                        <h6 className="title">{t('alerts.time_since_alert', { framework: 'React' })} </h6>
                        <hr />
                        <span id="difference_time">{timeSinceAlert}</span>
                    </div>
                 

                </div>

            </div>
            </div> : 
            <div>
                <h1 className="title">{t('alerts.expire', { framework: 'React' })} </h1>
            </div> }
            </div> : 
            <div>
                <h1 className="title">{t('alerts.not_found', { framework: 'React' })} </h1>
            </div>}
        </div>
    );
}