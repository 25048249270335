import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './app/store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AlertDetails } from './features/alerts/AlertsDetails';
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import common_en from "./translations/en/common.json";
import common_fr from "./translations/fr/common.json";
import common_gr from "./translations/gr/common.json";
import common_es from "./translations/es/common.json"
import { VideoFile } from './features/videoFile/VideoFile';
import { SnapshotFile } from './features/snapshotFile/SnapshotFile';
import { AlertFiles } from './features/alerts/AlertFiles';



i18next.init({
  interpolation: { escapeValue: false },  // React already does escaping
  lng: 'en',                              // language to use
  resources: {
    en: {
      common: common_en               // 'common' is our custom namespace
    },
    fr: {
      common: common_fr              // 'common' is our custom namespace
    },
    gr: {
      common: common_gr              // 'common' is our custom namespace
    },
    es: {
      common: common_es              // 'common' is our custom namespace
    },

    
  },
});

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    
    <Provider store={store}>
    <I18nextProvider i18n={i18next}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<App />}>
            <Route path="/alert" element={<AlertDetails />}/>
            <Route path="/files" element={<AlertFiles/>}/>
            

            
            
            
           
          </Route>
        </Routes>
      </BrowserRouter>
      </I18nextProvider>
    </Provider >
    
  </React.StrictMode >
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
