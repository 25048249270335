import { configureStore } from '@reduxjs/toolkit';
import alertsReducer from '../features/alerts/alertsSlice';

import headerReducer from '../features/header/headerSlice';
import videoFileReducer from '../features/videoFile/videoFileSlice';
import snapshotFileReducer from '../features/snapshotFile/snapshotFileSlice';

export const store = configureStore({
  reducer: {
    
    alerts:alertsReducer,
    header: headerReducer,
    videoFile: videoFileReducer,
    snapshotFile: snapshotFileReducer
    
  },
});
