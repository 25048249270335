import React, { useState, useEffect } from 'react';
import { Loader } from '@googlemaps/js-api-loader';
import './Map.css'
import gun_hires from './static/gun_hires.png'



export function Map({ lat, lng }) {
  const [map, setMap] = useState(null);
  const [marker, setMarker] = useState(null);

  useEffect(() => {
    const loader = new Loader({
      apiKey: 'AIzaSyB1UyfjptRUszzZKU4tnhVsbKOxuKpMchg',
      version: 'weekly',
    });

    loader.load().then(() => {
      const google = window.google;
      const map = new google.maps.Map(document.getElementById('map'), {
        center: { lat, lng },
        zoom: 17,
      });

      const markerImage = {
        url: gun_hires,
        scaledSize: new google.maps.Size(40, 40),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(20, 40),
      };

      const marker = new google.maps.Marker({
        position: { lat, lng },
        map,
        title: 'My location',
        icon: markerImage ,
        
      });

      setMap(map);
      setMarker(marker);
    });
  }, [lat, lng]);

  return <div id="map" className='content-box-rounded ' style={{ height: '400px'}}></div>;
}

