import React, { useState, useEffect } from 'react';
import { Loader } from '@googlemaps/js-api-loader';
import './Viewer.css'
import {MY_SERVER} from '../../app/globe'
import gun_hires from './static/gun_hires.png'


export function Viewer({ lat, lng }) {
    const [streetView, setStreetView] = useState(null);
    const [marker, setMarker] = useState(null);

  useEffect(() => {
    const loader = new Loader({
      apiKey: 'AIzaSyB1UyfjptRUszzZKU4tnhVsbKOxuKpMchg',
      version: 'weekly',
      
    });

    loader.load().then(() => {
        const google = window.google;
        const panorama = new google.maps.StreetViewPanorama(
          document.getElementById('viewer'),
          {
            position: { lat, lng },
            pov: { heading: 165, pitch: 0 },
            zoom: 1
          }
        );
        
        const markerImage = {
          url: gun_hires,
          scaledSize: new google.maps.Size(40, 40),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(20, 40),
        };


        const marker = new google.maps.Marker({
            position: {lat: lat, lng: lng },
            map: panorama,
            icon: markerImage ,
            
            
          });
          

        setStreetView(panorama);
        setMarker(marker);
    });
  }, [lat, lng]);

  return <div id="viewer" className='content-box-rounded' style={{ height: '400px'}}></div>;
}
