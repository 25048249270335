import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';



const initialState = {

  currentTime: "",
  


};








export const headerSlice = createSlice({
  name: 'header',
  initialState,
  reducers: {
    getCurrentTime: (state, action) => {
        const now = new Date(); 
        const utcNow = new Date(Date.UTC(
            now.getUTCFullYear(),
            now.getUTCMonth(),
            now.getUTCDate(),
            now.getUTCHours(),
            now.getUTCMinutes(),
            now.getUTCSeconds(),
            
        ))

        state.currentTime = utcNow
        


      
      }

  },

  
});



export const {getCurrentTime} = headerSlice.actions;
export const selectCurrentTime = (state) => state.header.currentTime

export default headerSlice.reducer;