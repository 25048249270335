import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { alertMessage, selectErrors, selectIsExpire, selectTimeUntilExpire, selectCameraAddress, selectCameraLocation, selectTimeSinceAlert, selectAlertTitle, selectAlertActions, selectAlertId, addAction, getTimeSinceAlert, selectCameraLatitude, selectCameraLongitude, getDetailsAlertAsync, changeAlertStatusAsync, declareAlertResolvedAsync, selectAlertStatus, selectCameraId, selectEdgeDeviceId, selectSnapshotPath, selectCreateAt, selectVideoPath, selectIsResolved } from "./alertsSlice"
import { MY_SERVER } from '../../app/globe'
import "./AlertDetails.css"
import { Map } from '../Map/Map'
import { Viewer } from '../3d_viewer/Viewer';
import share_image from './static/share.png'
import cloche_image from './static/cloche.png'
import undo_arrow from './static/undo_arrow.png'
import check_mark from './static/check_mark.png'
import { useTranslation } from "react-i18next";

// to test issues 


// need url like this https://my-website.com?id=cdf2de0664794807bf3444ba81a8c2fd
export const AlertDetails = () => {

    const { t, i18n } = useTranslation('common');

    const queryParameters = new URLSearchParams(window.location.search)
    const token = queryParameters.get("id")
    const dispatch = useDispatch();
    const timeUntilExpire = useSelector(selectTimeUntilExpire)
    const isExpire = useSelector(selectIsExpire)
    const title = new Date(useSelector(selectAlertTitle)).toLocaleString()
    const timeSinceAlert = useSelector(selectTimeSinceAlert)
    const alertId = useSelector(selectAlertId);
    const alertCreateAt = new Date(useSelector(selectCreateAt)).toLocaleString();
    const alertstatus = useSelector(selectAlertStatus);
    const cameraId = useSelector(selectCameraId);
    const snapshot = useSelector(selectSnapshotPath);
    const video = useSelector(selectVideoPath);
    const isResolvedBack = useSelector(selectIsResolved)
    const [isResolvedUser, setIsResolvedUser] = useState(false)
    const device_id = useSelector(selectEdgeDeviceId)
    const camera_latitude = useSelector(selectCameraLatitude)
    const camera_longitude = useSelector(selectCameraLongitude)
    const alertActions = useSelector(selectAlertActions)
    const [answer, setanswer] = useState(false)
    const cameraAddress = useSelector(selectCameraAddress)
    const cameraLocation = useSelector(selectCameraLocation)
    const errors = useSelector(selectErrors)
    const [firstMessageSend, setFirstMessageSend] = useState(false)
    const [showFullSnapshot, setShowFullSnapshot] = useState(false);
    const [buttonYesClicked, setbuttonYesClicked] = useState(null)
    const [buttonNoClicked, setbuttonNoClicked] = useState(null)


    const handleClickSnapshot = () => {
        setShowFullSnapshot(!showFullSnapshot);

    }

    useEffect(() => {
        dispatch(getDetailsAlertAsync(token))

    }, [])

    useEffect(() => {
        setInterval(() => {
            dispatch(getTimeSinceAlert())
        }, 1000);
    }, [])



    // useEffect(() => {
    //     setTimeout(() => {
    //         console.log(timeUntilExpire)
    //     }, 8000);
    // }, [])




    // useEffect(() => {
    //     setTimeout(() => {
    //         dispatch(getDetailsAlertAsync(token))
    //     }, timeUntilExpire);
    // }, [timeUntilExpire])


    // setTimeout(() => {
    //             dispatch(getDetailsAlertAsync(token))
    //         }, timeUntilExpire)





    // console.log(window.innerWidth)




    return (
        <div >


            {errors != 404 ? <div> {!isExpire ? <div>
                <h1 className="title">{t('alerts.alert', { framework: 'React' })} &rsaquo; {title} </h1>

                <div className="div-alertDetails gap-40">
                    <div className="w-700">
                        <div className="content-box box-shadow space-between">
                            <div className="flex gap-20 justify-between">
                                <h5 className="title">{cameraAddress}</h5>
                            </div>
                            <div className="video">
                                <video id="alert_video" src={video} controls autoPlay muted loop ></video>
                            </div>
                        </div>
                        <div id="is_resolved_status" className={`${isResolvedBack == false ? "emergency-message" : "resolved-message"} mt-16 text-center strong box-shadow space-between`}>{!isResolvedBack ? t('alerts.question', { framework: 'React' }) : t('alerts.resolved', { framework: 'React' })}</div>

                        <div>
                            {!isResolvedBack ? <div >

                                <div className="gap-buttons flex justify-around space-between" >
                                    <button id='yes_btn' style={{ borderRadius: "20px" }}
                                        className={`button-red flex ${buttonYesClicked == false ? 'unclicked-button' : buttonYesClicked == true ? 'clicked-button' : ''}`}
                                        onClick={buttonYesClicked != true ? () => {
                                            dispatch(changeAlertStatusAsync({ "alert_token": token, "alert_status": "is_emergency" }));
                                            setanswer(true)
                                            setbuttonYesClicked(true)
                                            setbuttonNoClicked(false)
                                            dispatch(addAction({ "alert_id": alertId, "action": "The alert was declared as EMERGENCY" }))
                                            setFirstMessageSend(true);


                                        } : ''}>
                                        <div>{t('alerts.yes', { framework: 'React' })}</div><img style={{ marginLeft: "10px" }} src={share_image} />
                                    </button>

                                    <button id='no_btn'
                                        className={`button-green ${buttonNoClicked == false ? 'unclicked-button' : buttonNoClicked == true ? 'clicked-button' : ''}`}
                                        onClick={buttonNoClicked != true ? () => {
                                            dispatch(changeAlertStatusAsync({ "alert_token": token, "alert_status": "is_not_emergency" }));
                                            setbuttonYesClicked(false)
                                            setbuttonNoClicked(true)
                                            setanswer(true)
                                            dispatch(addAction({ "alert_id": alertId, "action": "The alert was declared as NOT EMERGENCY" }))
                                        } : ''}>{t('alerts.no', { framework: 'React' })}
                                    </button>
                                </div>





                            </div> : ""}





                            {alertActions.map((action, i) => <div className='space-between-actions'>
                                <div className="flex box-shadow my-auto " style={{ backgroudColor: ' #3f7eb22f ', color: "#ffffff" }} >
                                    <a className="content-box  flex-1" >
                                        <div className="flex justify-between" style={{ alignItems: 'center' }}>
                                            <div id='image' >
                                                <img className='text-start'

                                                    src={action.actions == "The alert was declared as EMERGENCY" ? cloche_image

                                                        : action.actions == "The alert was declared as NOT EMERGENCY" ? check_mark

                                                            : action.actions == "UNDO" ? undo_arrow

                                                                : ""} alt="User" />

                                                {/* <strong style={{marginLeft:'20px',  width: '200px'}}  > action {alertActions.length - i }</strong> */}
                                            </div>
                                            <div style={{ paddingLeft: "12%" }}>
                                                <strong className="my-auto text-center "  >
                                                    {action.actions == "The alert was declared as EMERGENCY" ? t('alerts.declared_emergency', { framework: 'React' })

                                                    : action.actions == "The alert was declared as NOT EMERGENCY" ?  t('alerts.declared_unemergency', { framework: 'React' })

                                                        : action.actions == "UNDO" ? t('alerts.undo', { framework: 'React' })

                                                            : ""}</strong>
                                            </div>
                                            <div className="flex gap-8">
                                                <span className="my-auto light-text text-right " >{action.action_date} </span>
                                            </div>
                                        </div>
                                    </a>

                                </div>




                            </div>)}



                            <div className="content-box box-shadow space-between ">
                                <div className="flex justify-between" style={{ alignItems: 'center' }}>

                                    <img className='text-start'
                                        src={cloche_image} alt="User" />
                                    <strong className="text-center" style={{ width: "200px", color: "#ffffff", paddingLeft: "12%" }}>{t('alerts.alert', { framework: 'React' })} </strong>

                                    <div className="flex gap-8">
                                        <span className="my-auto light-text text-right alert-created-on" >
                                            {alertCreateAt} </span>

                                    </div>
                                </div>
                            </div>


                        </div>
                        {window.innerWidth <= 900 ? <div>
                            {!isResolvedBack ? <div>
                                {answer ?
                                    <div>
                                        <button
                                            name='resolve'
                                            style={{ marginTop: "30px", marginBottom: "30px" }}
                                            className='button-resolve' id="resolved_btn"

                                            onClick={() => {
                                                dispatch(declareAlertResolvedAsync({
                                                    "alert_token": token,
                                                    "alert_status": `${buttonYesClicked == true ? 'is_emergency_resolved' : "is_not_emergency_resolved"}`,
                                                    "actions": alertActions
                                                }));
                                                setIsResolvedUser(true)
                                            }}>

                                            {t('alerts.resolve', { framework: 'React' })}
                                        </button>
                                    </div>
                                    : ""}
                            </div>
                                : ""}
                        </div>
                            : ''}
                    </div>
                    <div className='div-column' >



                        <div className="map-iframe box-shadow">
                            {/* <iframe
                            src="https://maps.google.com/maps?q={{ alert.location_lat }}%2C{{ alert.location_lng }}&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=&amp;output=embed"
                            frameBorder="0" scrolling="no">
                        </iframe> */}


                            <Map lat={camera_latitude} lng={camera_longitude}></Map>
                        </div>


                        <div className="box-shadow">
                            <img id="myImg_ezechiel" src={snapshot} className="rounded flex snapshot_tall" alt="Demo" onClick={handleClickSnapshot} />
                            {showFullSnapshot && <div className={`fullscreen ${showFullSnapshot ? 'active' : ''}`}>
                                <img src={snapshot} alt="Demo" onClick={handleClickSnapshot} />
                            </div>}
                        </div>

                        {/* <div className="content-box-rounded box-shadow">
                        <h6 className="title"><img style={{ marginRight: "30px" }} src={MY_SERVER + '/static/images/cloche.png'}
                            alt="User" />instruction card</h6>
                        <hr />
                        <h5><span id="instruction_card">instruction card </span></h5>

                    </div> */}



                    </div>
                    <div className='div-column'>



                        <div className="map-iframe box-shadow ">
                            {/* <iframe
                            src="https://maps.google.com/maps?q={{ alert.location_lat }}%2C{{ alert.location_lng }}&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=&amp;output=embed"
                            frameBorder="0" scrolling="no">
                        </iframe> */}

                            <Viewer lat={camera_latitude} lng={camera_longitude}></Viewer>

                        </div>



                        {/* <div className="content-box-rounded box-shadow">
                        <h6 className="title"><img style={{ marginRight: "30px" }} src={MY_SERVER + '/static/images/cloche.png'}
                            alt="User" />instruction card</h6>
                        <hr />
                        <h5><span id="instruction_card">instruction card </span></h5>

                    </div> */}

                        <div className="content-box-rounded box-shadow tall-time-since-alert ">
                            <h6 className="title">{t('alerts.time_since_alert', { framework: 'React' })} </h6>
                            <hr />
                            <span id="difference_time">{timeSinceAlert}</span>
                        </div>
                        {window.innerWidth >= 900 ?
                            <div>
                                {!isResolvedBack ?
                                    <div>
                                        {answer ?
                                            <div>
                                                <button
                                                    name='resolve'
                                                    style={{ marginTop: "30px", marginBottom: "30px" }}
                                                    className='button-resolve' id="resolved_btn"

                                                    onClick={() => {
                                                        dispatch(declareAlertResolvedAsync({
                                                            "alert_token": token,
                                                            "alert_status": `${buttonYesClicked == true ? 'is_emergency_resolved' : "is_not_emergency_resolved"}`,
                                                            "actions": alertActions
                                                        }));
                                                        setIsResolvedUser(true)
                                                    }}>

                                                    {t('alerts.resolve', { framework: 'React' })}
                                                </button>
                                            </div>
                                            : ""}
                                    </div>
                                    : ""}
                            </div>
                            : ''}

                    </div>

                </div>
            </div> :
                <div>
                    <h1 className="title">{t('alerts.expire', { framework: 'React' })} </h1>
                </div>}
            </div> :
                <div>
                    <h1 className="title">{t('alerts.not_found', { framework: 'React' })} </h1>
                </div>}
        </div>
    );
}
