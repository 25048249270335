import axios from 'axios';
import {MY_SERVER} from '../../app/globe'




export function getAlertDetails(alert_token) {
      return new Promise((resolve) =>
        axios.get(MY_SERVER+`/alert/${alert_token}`)
        .then((res) => resolve({ data: res.data }))
        .catch(error => {
          if (error.response.status === 404) {
           
            resolve({ data: error.response.status })
            
          }
        })
      );
    }


    




   